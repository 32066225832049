import {base_url, tenls_url, tenls_region, ux_plugin_url, env} from '../config/apiConfig';
import * as _ from 'lodash';
// This allows mocking in unit tests
import * as thisModule from "./FetchService";


export const fetchFeatureFlags = () => {
    return fetch(`${base_url}/featureFlags`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
};

export const fetchTenlsFeatureFlags = (token) => {
    if (tenls_url === '') {
        return Promise.resolve({ status: 404, json: () => Promise.resolve() })
    }
    return fetch(`${_.replace(tenls_url, '%s', tenls_region)}/featureFlags?token=${token}`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
};

export const fetchOrders = (token, call2Tenls) => {
    const fetchURL = `${call2Tenls ? _.replace(tenls_url, '%s', tenls_region) : base_url}/orders?token=${token}`;
    return fetch(
        fetchURL,
        {
          method: 'GET',
        headers: {'Content-Type': 'application/json'}
        }
    );
};

export const fetchAccountNameValidation = (nameAttempt, call2tenls = false, region = 'us-ashburn-1', domainType = {}) => {
    //Get JWT toke from url query
    const token = new URLSearchParams(window.location.search).get('activationToken');
    const decoded = thisModule.jwt2Obj(token);
    const lHeaders = _.get(decoded, 'version', '') === 'V2' && call2tenls === false
        ? {'Content-Type': 'application/json', 'api-flow': 'PLC'}
        : {'Content-Type': 'application/json'};
    const tenlsURL = _.replace(tenls_url, '%s', tenls_region);
    const fetchURL = call2tenls ? `${tenlsURL}/accounts?token=${token}&cloudAccount=${nameAttempt}` : `${base_url}/validateAccountName`;
    const body = domainType && domainType.value && domainType.value === 'LightWeightDomain' ? JSON.stringify({ name: nameAttempt, domainType: domainType.value, token: token }) : JSON.stringify({name: nameAttempt, token: token});
    return fetch(
        fetchURL,
        {
            method: call2tenls ? 'GET':'POST',
            headers: lHeaders,
            body: call2tenls ? null : body
        }
    );
};

export const fetchBanner = () => {
  return fetch(
    `${base_url}/banner`,
    {
      method: 'GET',
      headers: {'Content-Type': 'application/json'}
    }
  );
};

export const fetchRegions = (path, call2tenls) => {
    const clientIdParam = path.includes('?') && path.includes('=') ? `&clientId=ActivateUI` : '?clientId=ActivateUI';
    const token = new URLSearchParams(window.location.search).get('activationToken');
    const fetchURL = `${call2tenls ? _.replace(tenls_url, '%s', tenls_region) : base_url}${path}${clientIdParam}${'&token=' + token}`;
    return fetch(
        fetchURL,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
    });
};

export const fetchPutCreateTenancy = (token, id, homeRegion) => {
    const fetchURL = `${_.replace(tenls_url, '%s', tenls_region)}/orders?token=${token}`;
    const body = JSON.stringify({id, homeRegion, token});
    return fetch(
        fetchURL,
    {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: body
    });
}

export const fetchCreateTenancy = (token, body, call2tenls, homeRegion ) => {
    const decoded = thisModule.jwt2Obj(token);
    const version = _.get(decoded, 'version', '');
    const lHeaders = version === 'V2' && call2tenls === false
                ? {'Content-Type': 'application/json', 'api-flow': 'PLC'}
                : {'Content-Type': 'application/json'};
    const fetchURL = `${call2tenls ? _.replace(tenls_url, '%s', ['dev', 'uat', 'uat2'].includes(env) ? tenls_region : homeRegion) : base_url}/tenancies?token=${token}`

  return fetch(
    fetchURL,
    {
      method: 'POST',
      headers: lHeaders,
      body: body
    }
  );
};

export const fetchAsset = (path) => {
    return fetch(
        `${ux_plugin_url}${path}`,
        { method: 'GET' }
    );
};

export const fetchStateId = (country) => fetch(
    `${base_url}/states?countryId=${country}`,
    {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    },
);

export const jwt2Obj = (token) => {
    let result;
    try {
        result = JSON.parse(Buffer.from(_.split(token, '.')[1], 'base64').toString());
    } catch {
        result = null;
    }
    return result;
};

export const getFeatureFlag = (featureName, featureFlags) => {
    if (!_.isEmpty(featureFlags)) {
        const index = _.findIndex(featureFlags, (o) => o.featureName === featureName);
        return index >= 0 ? featureFlags[index].isActive : false;
    }

    return false;
};

export const isInAllowedListedTokens = (token) => {
    const list = ['eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlcklkIjoib2NpZDEuYWNjb3VudHN1YnNjcmlwdGlvbi5vYzEuLnByb2QuYWFhYWFhYWF4YzJtZ3hoMnlqY2N2b3A3bmNhbmV4dWR3aTZheGQzNGk3eDYyN3N2NGVzZWw2YTZveDVxIiwiaXNzIjoiVExTIiwiaWF0IjoxNzI0Nzg1NzIyfQ.AnGRxfXYa61QTV1StovNAVmLWakL9SiO7SsfJEmgkbzcC5u8TdcSF2ASG6aHp2HlSAPBgd0Zd_3GPS4Z87k6JdQ0d93bhm_jnw6p_ciOgboMYTonEEV9lsIJmjLwxG4YZikTaQ74Czv-0sC5k7MgAjCRaSPbuP8eAJamojvMFtAf2VfFk6D045H5w_8-J4ATzicx9UhqLdp83bLgH8fHony5FBlw6UZLkGF2Gica7isOzV_9qUY9BfQo-GYkY-5_eFQ8oMgIku_4NLNciRl1x5J2nQXvevDUzvfKp1USNiarfrvrFqiYuZ_BtTWE7VOt_dldxVumqM8hsvPzc24eOyyNKUjOya6zaEbnPhOzdRJ3QaByKKOmC2ob1nCYmQsE9LOo8jo-107nWxwV30GYpkWNsZy2dVmq1xUgB-0rJKXQfV1PMRJejJwEAYYIGTj-n6mKW--d5VErEsqkAJI2UTQ_0xDVCFE19o80Xyx4RJ6IlVG39SegTp-mSeLpZ7A932okcyEUVuALYtW8TNOBwcC9iMwExBm9FVwbSA6ysP6BuCcpkxuxmbE0xF2TYH_3XHj42B802VBIFQxd42YoFu45WKc-h8oqrXkmLouwQ-gwiCjSw_jo8fHip7YmYGViCTRq3UofO4e6NOIOvgWP_tQE405ChWpbzjeFoi_iYlA',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlcklkIjoib2NpZDEuYWNjb3VudHN1YnNjcmlwdGlvbi5vYzEuLnByb2QuYWFhYWFhYWF5cmJtbmZxYnRzM2M2czZ5a3p5ZXJ4bWRyZmI2Y2p4bmRxZnJvYnk2eXBscWpncGVrajNhIiwiaXNzIjoiVExTIiwiaWF0IjoxNzI2MDg1MzE4fQ.YciWKT15LAtqHgkdqIOsybdbcMKKpilmrKvGMFf7FG0OEggnvqJMsKJWNGHbO5miGbA9Rj-T87EhAaOqlFjst3C_133cCUvahkmydNoRNg7H_yVgA5gO9WjuW8S84KSeYKgeAorwEqbXRHv0EHmYV6kajUZqmEGdANBCS-nUU16A-W4518QbDpJqyrZafZMR2sr3yPJcLWhv4uuv23I2htasjekkO0RWKLK6Ot0uxMT-9F_ZjAQ-sxakckJomOw7qvgqMitK9humIqLWKxI9rXgYPsAFUUaOUbYVAwXahtuVBRtzOK5PBUQCmHiDQIMl7SK7XFqcUI06rrWyPAGV-5zG_ITwzydzoUYRKV9GZvDtxcbf-WXH90mUZMZ2fLiWz9jZjPIwoyxnJVGYFADriTjlB4XXbVdT8wjPspQtMa_iqeYb5t-esfdE1lyCIiOJZDXP9odz73B1xBeI2MW3p0UL56fH1tvagHWepTYWwQ8o2q3EnksSbsw7GOkg57nwGKRMYEVSiSi804VhU4VHvUf-rFdiUqVKEPLrwOiUe7Hlq8wZDuHkqbWW2MMdFwCJEfk0RfMQt95DSUcOvUnJufoNWauciGwdpHBsmCpuyLZKKs9SwvsoUxThXOAHwxWBkS9YEaS4L2nH6NkNOEZz9fkmSBkaHEsGQMRLR03ulac',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlcklkIjoib2NpZDEuYWNjb3VudHN1YnNjcmlwdGlvbi5vYzEuLnByb2QuYWFhYWFhYWF5cmJtbmZxYnRzM2M2czZ5a3p5ZXJ4bWRyZmI2Y2p4bmRxZnJvYnk2eXBscWpncGVrajNhIiwiaXNzIjoiVExTIiwiaWF0IjoxNzI2MDg1MzE4fQ.YciWKT15LAtqHgkdqIOsybdbcMKKpilmrKvGMFf7FG0OEggnvqJMsKJWNGHbO5miGbA9Rj-T87EhAaOqlFjst3C_133cCUvahkmydNoRNg7H_yVgA5gO9WjuW8S84KSeYKgeAorwEqbXRHv0EHmYV6kajUZqmEGdANBCS-nUU16A-W4518QbDpJqyrZafZMR2sr3yPJcLWhv4uuv23I2htasjekkO0RWKLK6Ot0uxMT-9F_ZjAQ-sxakckJomOw7qvgqMitK9humIqLWKxI9rXgYPsAFUUaOUbYVAwXahtuVBRtzOK5PBUQCmHiDQIMl7SK7XFqcUI06rrWyPAGV-5zG_ITwzydzoUYRKV9GZvDtxcbf-WXH90mUZMZ2fLiWz9jZjPIwoyxnJVGYFADriTjlB4XXbVdT8wjPspQtMa_iqeYb5t-esfdE1lyCIiOJZDXP9odz73B1xBeI2MW3p0UL56fH1tvagHWepTYWwQ8o2q3EnksSbsw7GOkg57nwGKRMYEVSiSi804VhU4VHvUf-rFdiUqVKEPLrwOiUe7Hlq8wZDuHkqbWW2MMdFwCJEfk0RfMQt95DSUcOvUnJufoNWauciGwdpHBsmCpuyLZKKs9SwvsoUxThXOAHwxWBkS9YEaS4L2nH6NkNOEZz9fkmSBkaHEsGQMRLR03ulac',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlcklkIjoib2NpZDEuYWNjb3VudHN1YnNjcmlwdGlvbi5vYzEuLnByb2QuYWFhYWFhYWF0cDNpZ3BxZXVveHN6Z2x2cnNmbjNqMms0bnFidGt6cm9vMnVtdmdtc2p4YXNydGhodG9xIiwiaXNzIjoiVExTIiwiaWF0IjoxNzI1OTI2NzQzfQ.CJQLXMwLCvGhyMvIr0RgIfFEvk5qZs6GjsCumq4ybc4vLk47QbsrRXRLaQTeD-aJkzfWW9VF5J6olfA5dxbLBiLLj16FMd2nkyGFyG5bLhh9AXqkku-FABk6U34VW4o2C289OKrvb1ww6lH8lXU7sJF2o4L1efhfaxy8IwMxL15-4kkvBhM1_Y_-1CGc2oMLqJWkm3hULdzI_2x2lJ3AsHRhy41vMejzRwfqu3_TtRhCD6F8brV3XGlLGrcGqAlA5MM_IUriOU-Qo0mrHzigFMnxhvClZa4rUVD2Jp0t3RR0lUMbVUQwKnAFeEGsl6OmRxPpVt_EVnEqr1qbJbOEKB0ITRKzCw_Ted_2txKAWa3ErigeJz0OGuKPUSAVY-XwjvMKSNUbyIx-_c7sy8j4EDs0vrFlQ8tb4k8uvQIXafWd9Rv7qQgBxC5oyV3Jm8ItCADSW6nlcP6vJ_nQPIy4OkgoK_8QAeIjOY6n68RYX-5PGg9qu0JfIIG0fwHk5oTwJpGNSQmbhFOkUvUWRnrzmlDKFVaO4-RhkI8KO5NHGcfKTWir44ZJwzGe6bq8HXlNAvnVDENWwQ526tlrNQhk_QV0z-T3sen8usHeAIMfTvs9AmA02GDuFvHpOcTFQ2qDDRMOjaKG50D-szMyvWD_inlOhbhLGidNERm5-j3O0QQ',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlcklkIjoib2NpZDEuYWNjb3VudHN1YnNjcmlwdGlvbi5vYzEuLnByb2QuYWFhYWFhYWFxdjJsdWtibnpra2MyZXZkcnV0dm5qdDNpajI3aG1rbngyMnJkNmpveXNtbmRqZWRsaGVhIiwiaXNzIjoiVExTIiwiaWF0IjoxNzI1MDU4NjE3fQ.Oy-KUSBxpz5djaQYjWP2sgtVY3AtV0JUmgDdPNd-OHELUVQR5mim4GwzAC85ghGy1CNLpxJ0ZmT1FA61-cLzmeBP7uZhFs5OPC9S6dcRSeRJ85ShxcOQOR6Bv5HhKTm0NtDevcgpXouxyDjBKEr_sLa6wyujYnB_XhthDpaUgoxCPLhtah2cHdVz4CxuwriGlYIAiHvIEKnoQs0PuQSZYu7tG8mh0rXXVHp62lLc1Y3ddYrhvIRVmzp9CAcDnAoWxy9csOCXfx97uhtUp2RvGy0UofMQkT6gDYpwrmgze7hPd64DDRSyERlpSM6yiiWwqqxL1weIu7ZmPjM6VQ-Y6seSIHZp3Ej_v2D36OYGKDYDr690Byo7ilxVv1zstBjPMAlZe7y0KXC6CDaSshjdasTVdmupN3ls21iiyxoqyFy6bEE0pHpbh5LCBBrYdoANajESI-yAxPKw7bPyHJEw4q57FaKM8DcaVPwj4D7KX8pzfDN6usMcFLFACA4_cWNoZrQPekJxG2BFqy5y8Qviz22P7Pk8IKiyln70OD0b3Py9DVx0yCEpCYu50OBqml0U-E5pFBqFxz7ot0GZftjNafiwjK0Jj3tdl99j7vlrhzrsYq72hHSadyF2QfbG9YNJV0iRQB3sd9T84FWfBEUGOPOexlsmEbXAptlqBRP0qTs',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlcklkIjoib2NpZDEuYWNjb3VudHN1YnNjcmlwdGlvbi5vYzEuLnByb2QuYWFhYWFhYWEyNzc2eTNsNDNzbHlhaHd6aGt2b3YzdXR3bWlicXJ2eXVkMmNidXY0ajJ2bWk2dnE2ZTJxIiwiaXNzIjoiVExTIiwiaWF0IjoxNzI0OTYxMDY3fQ.eEsKt2pmWHxkyTlmwGBH1BVqUShhC368PwE6kaN_ZYmyfxxTp2c-1vOEOWso5T1Ugf-b5erWrJfMPaoW12OnkO8sn6OOzW3BY8hL-9DF3dg4Ficl37FEXNu7LiPciaMfrtHrkrJnAjRzvZGF2q5UqeXll7GZIaFvaykdypHF_yWpO39FUevpJClBYLYeJeEgdmSlFkOAmrMKMlmfmsB_FDh7MADP5oRt9Ja_1dTl3AHdiM6bUFDLqFiQSMcavMOItomTP7JKdF8xnj1nvjbSlCD7MoEeVDxEIXHD9X_8dlE_gVdn0MElFsE2DEXnNLL8lVSFVLN57XaDt6p5bWiT-NDzyDVLKM-6pLJsOVk0Yn9qHPF96-gj4nLAzktXNtpHVm8qYT_4sAJPibOmca0CjwRKg7JWqX3g5ZUyIyeqUaBu0IAyiSyuHAJRFDHngXFqcLkazR2l2ChYD16bsW7BNDDW8wWxM1gFVp2kpqFVuKCvQ_CZ0Af6LRX6x5jdfDnjArPl2fDzAEr_0Nx4rxYAPTmEMKbYHzgnuK4MWnIorafrWDVxq_x3kaiMN6_JAqjXxSGm2nNYnbf8s3w0Mx_URmuJenuUwgPG2fd6Rqn70HR7nNRwhaGIatzQ_g_VgFZRqV7cqVx6An_vat4bq9DKOyzbi-16djMt0qngQhwmQ9U',
                  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJzdWIiOiJPcmRlckFjdGl2YXRpb24iLCJvcmRlck51bWJlciI6IjQxNjk0MjEwYzMxMTAwMTcwYzExSzE5MzUxMTVQMzc5NTkxOSIsIm9yZGVySWQiOiJvY2lkMS5hY2NvdW50c3Vic2NyaXB0aW9uLm9jMS4ucHJvZC5hYWFhYWFhYXlxNXJmcTQ2ZjdtdXVnaGZtZ2RuM3FjNWRreHQ0cGN3bHloaGUyamdqN2toY3JxNXNrdHEiLCJpc3MiOiJUTFMiLCJpYXQiOjE3MjQ4ODI1ODYsInZlcnNpb24iOiJWMyJ9.aOFHLTNMW_0LBnMx_qq-WA3G1l7qfSs51WTNkLd8Fg_sMP_2FZ-VR_LzCuDgcSMJQzunuIKrrPs_obRqSDR2QocVv75LSgG5AF_IGnx-oRFJX06TwLJCgqLs_pwXTOwFEKEcyNljmUmgMcCLaMd5LsduRmOR9DrAoldtzm_g-49J8uhOekXJDGcGjZ4QgJcibqeQBgJro8n7tXryDw7Xw6Cb2CyK7PJ26L68VuNWVg8iwxHXFUOEYCh_irbH7XqFg1vct1dKzK-gkxvrc-yJYVpuLnO5BAYnaP09HMz2pyG5-gN9Pk7l-Wwhoqv_wTayRELcMG2mSRtvi7sRQPlQswMEUXpu2DEgFvaRb5RL4L63lAax-8ZcFoIGGcFvukVecvBpS1H7wSvce6mFdnyEdi6zQzWziaGt_hAUgTGBrMZEHnz-V24nrDDzji6iJpZCuudg57NIKvcW7ReG7FlsBtYgrL1n3Wu2I4GRbC287Q8osejUZyadhiEEFhfR-N6LJ5BXSpnKFcHk6oL1UhUDJ35PZOIBbs32k24HTZxI0184y4YCdsQYALd9jETVTFUqS9qiiTpWMCThpCCgq5PueppOpwU8xEo8ICgI49_IeDqjv54ORjo9nCMqMPxemRggo_EFkW7l42Q7CC_IKdkoCRrXtAWTtf5ldZhj5HhWbiM'
                 ];
    return list.includes(token);
}

